body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.grid {
  display: grid;
  gap: 1rem;

  grid-template-columns: 1fr 1fr 1fr 1fr;
  /* grid-auto-rows: 240px; */
}

.card-wide {
  grid-column: span 4 / auto;
}

.card-small {
  grid-column: span 2 / auto;
}
